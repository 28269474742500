// '-m' for mobile
$font-size-xxl: 70
$font-size-xl-m: 21
$font-size-xl: 35
$font-size-lg-m: 14
$font-size-lg: 15
$font-size-md-m: 13
$font-size-md: 14
$font-size-sm-m: 11
$font-size-sm: 12
$font-size-xs: 10

$font-size: () !default
$font-size: append($font-size, ("xxl", $font-size-xxl))
$font-size: append($font-size, ("xl", $font-size-xl))
$font-size: append($font-size, ("xl-m", $font-size-xl-m))
$font-size: append($font-size, ("lg", $font-size-lg))
$font-size: append($font-size, ("lg-m", $font-size-lg-m))
$font-size: append($font-size, ("md", $font-size-md))
$font-size: append($font-size, ("md-m", $font-size-md-m))
$font-size: append($font-size, ("sm", $font-size-sm))
$font-size: append($font-size, ("sm-m", $font-size-sm-m))
$font-size: append($font-size, ("xs", $font-size-xs))


@each $size, $value in $font-size
  .font-size_#{$size}
    +mq-both-adaptive
      font-size: #{$value}px
    // &--
    //   &lh
    //     +mq-both-adaptive

    //       @if $value < 30
    //         line-height: 1.9
    //       @else 
    //         line-height: 1.2






