@font-face
  font-family: 'Montserrat'
  src: url('../fonts/Montserrat-Black.ttf') format("truetype")
  font-weight: 900
  font-style: normal
  font-display: swap
@font-face
  font-family: 'Montserrat'
  src: url('../fonts/Montserrat-Bold.ttf') format("truetype")
  font-weight: 700
  font-style: normal
  font-display: swap
@font-face
  font-family: 'Montserrat'
  src: url('../fonts/Montserrat-Medium.ttf') format("truetype")
  font-weight: 500
  font-style: normal
  font-display: swap
@font-face
  font-family: 'Montserrat'
  src: url('../fonts/Montserrat-Regular.ttf') format("truetype")
  font-weight: 400
  font-style: normal
  font-display: swap
