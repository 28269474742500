body
  font-family: 'Montserrat', sans-serif
  

html, body 
  height: 100%

body 
  display: flex
  flex-direction: column
  overflow-x: hidden

  
