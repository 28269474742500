$color-white: #ffffff
// $light: #f7f8f9
// $light-2: #dfe3e8
$color-lightblue: #2cc1ea
$color-blue: #1dace0
$color-gray: #878e95
$color-darkgray: #435467
$color-black: #000000
$color-red: #ff0000

$color-group: ()!default
$color-group: append($color-group, ("lightblue", $color-lightblue))
$color-group: append($color-group, ("white", $color-white))
$color-group: append($color-group, ("red", $color-red))
$color-group: append($color-group, ("blue", $color-blue))
$color-group: append($color-group, ("gray", $color-gray))
$color-group: append($color-group, ("darkgray", $color-darkgray))
$color-group: append($color-group, ("black", $color-black))

.color
  @each $color, $value in $color-group
    &_#{$color}
      color: $value

     