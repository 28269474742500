.footer-wrapper
  flex-shrink: 0
.footer
  &--
    &padding_
      &sm
        pa: 5px 0 20px
        +mq-lg()
          pa: 45px 0
      &lg
        pa: 30px 0 30px
        +mq-lg()
          pa: 65px 0 34px
    &white
      background-color: $bg-color-white
    &light
      display: none
      +mq-md
        display: block
        background-color: $bg-color-light