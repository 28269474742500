.btn
  outline: none
  cursor: pointer
  
  &--
    &width_
      &full
        width: 100%
      &ld
        w: 200px
    &color_
      &blue
        bg-color: $bg-color-blue
        
        text-align: center
        color: $color-white
        fw: 700
        fs: 14px
        transition: .3s
        height: 43px
        padding: 15px 0
        +mq-lg()
          h: 59px
          pa: 23px 0
          
        &:hover
          bg-color: lighten($bg-color-blue, 10%)
          transition: .3s

