.multiselect__tags
  border: none!important
  border-radius: 0!important
  bg-color: $bg-color-light!important
  min-height: 33px!important
  padding: 5px 40px 0 8px!important
  +mq-lg()
    min-height: 58px!important
    padding: 18px 42px 0 8px!important


.multiselect__input, .multiselect__single
  background: none!important
  fs: 13px !important
  fw: 500
  color: $color-gray!important

.multiselect__option
  fs: 13px !important
  fw: 500
  color: $color-gray!important

.multiselect__option--highlight
  bg-color: $bg-color-blue!important
  color: $color-white!important

.multiselect__select
  h: 33px!important
  +mq-lg()
    margin-top: 13px!important


