.section
  &--
    &main
      width: 100%
      background-position: left!important
      background-repeat: norepeat
      background-size: cover
      +mq-md()
        background-position: center top!important
    &notfound
      background-repeat: norepeat
      background-size: cover
      padding: 70px 0
      +mq-lg
        width: 100%
        pa: 600px 0
        height: 70vw
        padding: 259px 0



