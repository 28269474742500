.logo 
  width: 200px
  h: 24px
  position: absolute
  left: 0
  right: 0
  margin: 0 auto
  +mq-lg()
    width: 244px
    position: relative
    h: 29px
    ma: 0 0
    left: none
    right: none

.section-pic
  w: 100%
  h: 227px
  +mq-lg()
    h: 359px


.background
  w: 202px
  h: 1036px
  position: absolute!important
  left: 0
  display: none
  +mq-lg()
    display: block

.logo-footer
  w: 199px
  h: 24px

.form-background
  // width: 100%
  h: 62px
  // position: absolute!important
  // background-image: url(/static/img/expert-2.png)
  background-repeat: no-repeat
  background-position: bottom
  background-size: contain
  width: 100vw
  position: absolute
  bottom: 0px
  +mq-lg()
    height: 194px
    // bottom: -100px
  +mq-xl()
    h: 360px

.not-found-bg
  // width: 100%
  h: 62px
  // position: absolute!important
  // background-image: url(/static/img/expert-2.png)
  background-repeat: no-repeat
  background-position: bottom
  background-size: contain
  width: 100vw
  position: absolute
  bottom: 97px
  +mq-lg()
    height: 194px
    // bottom: -100px
  +mq-xl()
    h: 360px


.icon
  width: 37px
  height: 37px
  ml: 5px

.not-found-bg
  w: 100%
  h: 90vh
  // background-image: url(/static/img/bg.png)
  bg-color: $bg-color-light
  pa: 30px 0
  +mq-lg()
    pa: 100px 0

.footer-image
  w: 100%
  h: inherit



