$padding_xxxxl: 120px
$padding_xxxl: 100px
$padding_xxl: 95px
$padding_xl: 90px
$padding_lg: 75px
$padding_md: 65px
$padding_sm: 50px
$padding_xs: 45px
$padding_xxs: 40px
$padding_xxxs: 35px

$padding: () !default
$padding: append($padding, ('xxxxl', $padding_xxxxl))
$padding: append($padding, ('xxxl', $padding_xxxl))
$padding: append($padding, ('xxl', $padding_xxl))
$padding: append($padding, ('xl', $padding_xl))
$padding: append($padding, ('lg', $padding_lg))
$padding: append($padding, ('md', $padding_md))
$padding: append($padding, ('sm', $padding_sm))
$padding: append($padding, ('xs', $padding_xs))
$padding: append($padding, ('xxs', $padding_xxs))
$padding: append($padding, ('xxxs', $padding_xxxs))

.padding
  @each $size, $value in $padding
    &_#{size}
      padding: $value
