.sidebar-menu
  position: fixed
  h: 100%
  left: -220px
  w: 220px
  top: 0
  transition: 1s
  z-index: 100
  pa: 35px 38px 30px 
  background-color: $color-darkgray
  height: 100vh
  &.is-active
    left: 0
    top: 0
    transition: 1s

  &__
    &close-btn
      opacity: 1
      color: $color-white
      position: absolute
      right: 20px
      top: 12px
      fs: 20px
      
