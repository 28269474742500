$line-height-xl: 48px
$line-height-lg: 43px
$line-height-mid: 35px
$line-height-md: 25px
$line-height-sm: 20px

$line-height-group: ()!default
$line-height-group: append($line-height-group, ("xl", $line-height-xl))
$line-height-group: append($line-height-group, ("lg", $line-height-lg))
$line-height-group: append($line-height-group, ("mid", $line-height-mid))
$line-height-group: append($line-height-group, ("md", $line-height-md))
$line-height-group: append($line-height-group, ("sm", $line-height-sm))

@each $size, $value in $line-height-group
  .line-height
    &_#{$size}
      +mq-both-adaptive
        lh: $value
        
      

    //  &_#{$size}
    //   @if (#{$size} == 'xl')
    //     lh: calc(#{ $value} - 10px)
    //   @else
    //     lh: $value
        
      
    //   +mq-lg()
    //     lh: $value
