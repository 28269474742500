$weight-black: 900
$weight-bold: 700
$weight-semibold: 500

$weight: () !default
$weight: append($weight, ("black", $weight-black))
$weight: append($weight, ("bold", $weight-bold))
$weight: append($weight, ("semibold", $weight-semibold))

.font-weight
  @each $size, $value in $weight
    &_#{$size}
      font-weight: $value
     