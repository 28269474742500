.link-social
  display: block
  width: 39px
  height: 39px
  bg-color: $bg-color-light
  border-radius: 50%
  text-decoration: none
  text-align: center
  pa: 10px
  transition: .3s
  mr: 10px
  color: $color-blue
  &:hover
    color: lighten($color-blue, 10%)
    transition: .3s
    bg-color: darken($bg-color-light, 10%)
  &--
    &twitter
      &:before
        fs: 18px
        font-family: "Material Design Icons"
        content: '\F544'
    &facebook
      &:before
        fs: 18px
        font-family: "Material Design Icons"
        content: '\F20C'
    &instagram
      &:before
        fs: 18px
        font-family: "Material Design Icons"
        content: '\F2FE'