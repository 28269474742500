.#{$GRID_ROW_PREFIX}row
  
  &--
    &justification_
      &between
        justify-content: space-between
      &around
        justify-content: space-around
      &start
        justify-content: start
      &end
        justify-content: flex-end
      &center-between-lg
        justify-content: center
        +mq-lg()
          justify-content: space-between
      &center-between-md
        justify-content: center
        +mq-md()
          justify-content: space-between
    &align_
      &center
        align-items: center
      &left
        align-items: left
      &start
        align-items: start
      &end
        align-items: baseline
    &nowrap
      flex-wrap: nowrap
    &nowrap-lg
      flex-wrap: wrap
      +mq-lg()
        flex-wrap: nowrap