.#{$DERMIS_PREFIX}link
  &--
    &size_
      &sm
        fs: 12px
      &xs
        fs: 10px
    &color_
      &gray
        color: $color-gray!important
        transition: .3s
        &:hover
          color: $color-blue!important
          transition: .3s
      &blue
        color: $color-blue!important
        transition: .3s
        &:hover
          color: $color-gray!important
          transition: .3s
    &block
      display: block!important
    &weight_
      &medium
        fw: 500

