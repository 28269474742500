.item-bottom
  position: absolute
  bottom: 0

.general-content 
  flex: 1 0 auto
  mb: -103px

.position
  &--
    &relative
      position: relative

.anti-rotate
  transform: rotate(-90deg)

.z-index
  &--
    &1
      z-index: 1
    &2
      z-index: 2
    &0
      z-index: 0

.margin-right
  &--
    &sm
      mr: -10px


.margin
  &--
    &cols
      mr: -10px
      ml: -10px

.copyright-position
  position: relative
  +mq-lg()
    position: absolute
    bottom: 0

.padding
  &--
    &cols
      pr: 0px
      pl: 0px

.white-space
  white-space: pre-wrap

.width
  &--
    &500
      w: 292px
      +mq-lg()
        w: 500px

.error-wrapper
  position: relative
  ma: 0 auto

.display
  &--
    &block
      display: block
    &inline
      display: inline

.bg-b
  bg-color: $bg-color-blue
  
.shadow
  box-shadow: 0 28px 28px 2px rgba(79, 86, 103, 0.08)

.padding-20
  pa: 20px
  +mq-lg
    pa: 40px