$bg-color-white: #ffffff
$bg-color-light: #f7f8f9
$bg-color-light-2: #dfe3e8
$bg-color-lightblue: #2cc1ea
$bg-color-blue: #1dace0
$bg-color-gray: #878e95
$bg-color-darkgray: #435467

$bg-color-group: ()!default
$bg-color-group: append($bg-color-group, ("white", $bg-color-white))
$bg-color-group: append($bg-color-group, ("light", $bg-color-light))
$bg-color-group: append($bg-color-group, ("light-2", $bg-color-light-2))
$bg-color-group: append($bg-color-group, ("blue", $bg-color-blue))
$bg-color-group: append($bg-color-group, ("lightblue", $bg-color-lightblue))
$bg-color-group: append($bg-color-group, ("gray", $bg-color-gray))
$bg-color-group: append($bg-color-group, ("darkgray", $bg-color-darkgray))

.bg-color
  @each $bg-color, $value in $bg-color-group
    &_#{$bg-color}
      background-color: $value
     