.fieldset
  &__


    &placeholder
      color: $color-gray
      font-size: 11px
      font-weight: 500
      display: block
      ma: 10px 0 17px 0
      position: absolute
      pointer-events: none
      transform: translate(17px, 4px)
      +mq-lg()
        font-size: 13px
        line-height: 18px

    &input
      width: 100%
      outline: none
      height: 33px
      padding: 9px
      color: $color-gray
      fs: 13px
      fw: 500
      background-color: $bg-color-light
      mb: 15px
      +mq-lg()
        pa: 17px
        height: 42px
        mb: 22px
        height: 59px
        

    
.error
  // color: $red
  fs: 13px
  ma: 5px 0
  position: relative
  right: 0
  
 
input
  &.error
    outline: none

 
.button-form
  w: 100%
  mt: 20px

.form-wrapper
  ma: 0 -8px

