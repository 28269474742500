.chevron
  background: $bg-color-white
  display: inline-block
  height: 52px
  position: relative
  width: 57px
  transform: rotate(90deg)

  &:before
    border-bottom: 19px solid $bg-color-white
    border-left: 28px solid transparent
    border-right: 28px solid transparent
    content: ""
    height: 0
    left: 0
    position: absolute
    top: -19px
    width: 0