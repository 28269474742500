.sidebar-menu-text
  text-decoration: none
  color: $color-white
  fs: 12px
  lh: 16px
  position: relative
  pa: 11px 0
  text-transform: uppercase
  fw: 700
  white-space: nowrap
  transition: .3s
  display: block
  white-space: normal
  border-bottom: 1px solid $color-gray


.list
  list-style: none
  list-style-type: none
  
  