.menu
  text-align: left
  +mq-lg()
    display: flex
    margin-top: 22px
  &__
    &link
      text-decoration: none
      color: $color-blue
      fs: 12px
      lh: 16px
      position: relative
      pa: 11px 0
      text-transform: uppercase
      fw: 700
      white-space: nowrap
      transition: .3s
      list-style: none
      list-style-type: none
      display: none
      fw: 700
      +mq-lg()
        fw: 700 
        fs: 13px
        color: $color-darkgray
        display: block
        fs: 11px
        cursor: pointer
        ml: 40px
        &:hover
          color: $color-blue
          transition: .3s
      +mq-xl()
        fs: 13px
        ml: 80px

