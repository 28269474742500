.text
  ff: 'Montserrat', sans-serif
  &--
    &uppercase 
      text-transform: uppercase
    &line-through
      text-decoration: line-through
    &decoration-none
      td: none
    &letter-spacing
      letter-spacing: 2px
    &center
      text-align: center
    &normal
      text-transform: none
    &right
      text-align: right
    &center-to-left
      text-align: center
      +mq-lg()
        text-align: left




