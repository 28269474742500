.bgi-basic
  bg-repeat: no-repeat
  background-size: cover
  background-position: center
  position: relative
  overflow: hidden
  img
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    max-width: initial
    object-fit: cover


.img-basic
  width: 100%
  height: 100%

.bgi-basic-contain
  bg-repeat: no-repeat
  background-size: cover
  background-position: center
  position: relative
  overflow: hidden
  img
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    max-width: initial
    object-fit: contain
