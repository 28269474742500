.#{$DERMIS_PREFIX}caption
  font-family: 'Montserrat', sans-serif
  color: none

  &--
    &size_
      &xl
        fs: 27px
        +mq-lg()
          fs: 35px
      &7
        fs: 12px
        fw: 700
    &color_
      &darkgray
        color: $color-darkgray

