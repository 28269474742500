::-webkit-scrollbar 
  width: 5px
  height: 5px
  ma: 10px
  pa: 10px
  +mq-lg()
    width: 10px
    height: 10px
    

::-webkit-scrollbar-button 
  background-color: $bg-color-blue
  display: none
   
::-webkit-scrollbar-track 
  background-color: #999
  
::-webkit-scrollbar-track-piece 
  background-color: #ffffff
  opacity: 0
  
::-webkit-scrollbar-thumb 
  height: 50px
  background-color: $bg-color-blue
  
::-webkit-scrollbar-corner 
  background-color: #999
  
::-webkit-resizer 
  background-color: $bg-color-white
