.sidebar-button
  display: block
  color: $color-darkgray
  fs: 25px
  // ml: 10px
  transition: 1s
  opacity: 1

  +mq-lg()
    display: none

.search
  color: white
  transition: .3s
  cursor: pointer
  &:hover
    color: $color-blue
    transition: .3s
  &--footer
    ma: 0 auto
    +mq-lg()
      margin-left: 50px
      margin-top: -37px