.nav-mob
  bg-color: $color-blue
  display: block
  pa: 30px 0 23px

  +mq-lg()
    display: none

body
  left: 0%
  transition: 1s


.fixed
  left: 220px
  mr: -5px
  position: relative
  transition: 1s

.mobile
  display: block
  +mq-lg()
    display: none

.desktop
  display: none
  +mq-lg()
    display: block

