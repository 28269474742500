.checkbox
  label 
    position: relative
    cursor: pointer
    fs: 10px
    color: $color-gray
    &:before 
      position: absolute
      // top: 0
      pa: 4px
      background-color: $bg-color-light
      border: 1px solid $bg-color-gray
      content: '\F12C'
      font-family: "Material Design Icons"
      fs: 15px
      mr: 15px
      position: relative
      float: left
      fw: 700
      transition-timing-function: ease-in-out
      transition-duration: 0.3s
      color: $bg-color-light
      mb: 40px
      +mq-sm()
        margin-bottom: 10px


  input[type="checkbox"] 
    display: none

  input[type="checkbox"]:checked ~ label
    &::before
      pa: 4px
      background-color: $color-white
      content: '\F12C'
      font-family: "Material Design Icons"
      fs: 15px
      position: relative
      fw: 700
      color: $color-blue
      transition-timing-function: ease-in-out
      transition-duration: 0.3s


