.text-editor
  .table-wrapper
    max-width: 100%
    overflow: auto
    overflow-x: scroll
    

  h1
    mb: 50px
    mt: 60px
    fs: 21px
    lh: 35px
    color: $color-darkgray!important
    +mq-lg()
      fs: 35px
      mt: 50px
      lh: 50px
      mb: 40px


  h2
    fs: 20px
    color: $color-darkgray!important
    mb: 20px
    mt: 30px
    lh: 34px
    +mq-lg()
      fs: 30px
      lh: 37px
      mt: 35px
      mb: 25px

  h3
    fs: 18px
    color: $color-darkgray!important
    mb: 25px
    lh: 27px
    +mq-lg()
      fs: 25px
      lh: 30px

  h4
    fs: 16px
    color: $color-darkgray
    mb: 18px
    lh: 23px
    +mq-lg()
      fs: 20px
      lh: 25px


  h5
    fs: 14px
    color: $color-darkgray
    mb: 20px
    lh: 19px
    +mq-lg()
      fs: 15px
      lh: 20px

  h6
    fs: 13px
    color: $color-darkgray
    mb: 20px
    lh: 20px
    +mq-lg()
      fs: 14px
      lh: 18px


  b
    fw: 900

  blockquote
    fs: 12px !important
    background-color: white !important
    font-style: italic !important
    position: relative !important
    font-weight: 400 !important
    margin-top: 20px !important
    padding: 30px !important
    +mq-xl
      fs: 14px !important
      margin-top: 30px !important
    &:after
      background-color: #E9ECF2 !important
      position: absolute !important
      height: 100% !important
      width: 30px !important
      left: 0 !important
      top: 0 !important
      content: ""

  strong
    font-weight: 700

  iframe
    height: 250px
    width: 300px
    ma: 30px 0
    +mq-sm()
      height: 300px
      w: 350px
    +mq-md()
      height: 420px
      w: 600px
      ma: 70px 0
    +mq-lg()
      height: 600px
      w: 700px
      ma: 100px 0
    +mq-xl()
      height: 700px
      w: 800px

  img
    max-width: 100%
    height: auto !important
    margin: 55px 0

  ol, ul
    position: relative
    margin: 40px 0
    li
      margin-bottom: 20px
      color: $color-gray
      padding-top: 8px
      padding-left: 17px
      position: relative
      line-height: 22px
      font-weight: 500
      font-size: 12px
      +mq-lg
        padding-left: 43px
        font-size: 14px
        line-height: 26px

  ol
    counter-reset: section
    li
      &::before
        counter-increment: section
        content: "0" counter(section)
        position: absolute
        color: $color-blue
        font-weight: 700
        left: 0
        font-size: 13px
        +mq-lg()
          font-size: 17px
  ul
    li
      &:before
        background-color: $bg-color-blue
        position: absolute
        height: 10px
        mt: 3px
        width: 10px
        left: 1px
        top: 11px
        content: ""

  h3, h2, h1
    margin-bottom: 30px
    color: $color-darkgray

  p
    font-size: 12px
    font-weight: 500
    line-height: 22px
    color: $color-gray
    mb: 30px
    +mq-lg()
      font-size: 14px
      line-height: 26px
      mb: 40px


  a
    transition: .5s all ease
    text-decoration: none
    display: inline-block
    line-height: 24px
    cursor: pointer
    color: $color-blue
    font-size: 12px
    transition: .3s
    +mq-lg()
      fs: 14px
      &:hover
        color: darken($color-blue, 20%)
        transition: .3s


  table
    overflow: hidden
    width: 100%
    margin: 40px auto 
    overflow: auto
    overflow-scroll: touch
    fs: 12px
    +mq-lg()
      fs: 14px
  
  
  th, td 
    padding: 10px 20px 10px 
    text-align: center
    fs: 12px
    lh: 20px
    fw: 500
    +mq-lg()
      padding: 18px 28px 18px 
      fs: 14px
      lh: 22px
  
  th 
    padding-top: 22px 
    text-shadow: 1px 1px 1px #fff
    background: $bg-color-light-2
    fw: 700
    fs: 12px
    lh: 20px
    color: $color-darkgray
    +mq-lg()
      fs: 14px
      lh: 22px

  tr:nth-child(odd) 
    background: $bg-color-light

  tr:nth-child(even) 
    background: $bg-color-white

  
  
  tr.odd-row td 
    background :#f6f6f6
  
  
  td.first, th.first 
    text-align: left
  

  
  
  // td 
  //   background: -moz-linear-gradient(100% 25% 90deg, #fefefe, #f9f9f9)
  //   background: -webkit-gradient(linear, 0% 0%, 0% 25%, from(#f9f9f9), to(#fefefe))
  
  
  // tr.odd-row td 
  //   background: -moz-linear-gradient(100% 25% 90deg, #f6f6f6, #f1f1f1)
  //   background: -webkit-gradient(linear, 0% 0%, 0% 25%, from(#f1f1f1), to(#f6f6f6))
  
  
  // th 
  //   background: -moz-linear-gradient(100% 20% 90deg, #e8eaeb, #ededed)
  //   background: -webkit-gradient(linear, 0% 0%, 0% 20%, from(#ededed), to(#e8eaeb))
  
  
  tr:first-child th.first 
    -moz-border-radius-topleft: 5px
    -webkit-border-top-left-radius: 5px 
  
  
  tr:first-child th.last 
    -moz-border-radius-topright: 5px
    -webkit-border-top-right-radius: 5px 
  
  
  tr:last-child td.first 
    -moz-border-radius-bottomleft: 5px
    -webkit-border-bottom-left-radius: 5px 
  
  
  tr:last-child td.last 
    -moz-border-radius-bottomright: 5px
    -webkit-border-bottom-right-radius: 5px 
  
  blockquote
    // background-color: #f2f9f2
    // border-left: 3px solid $color-blue
    ff: 'Montserrat', sans-serif
    pa: 40px
    font-style: normal!important
    ma: 45px 0
    fw: 700!important
    color: $color-darkgray!important
    lh: 26px
    fs: 14px 
    +mq-lg()
      lh: 20px
      fs: 12px 

    &::after
      background-color: $bg-color-blue!important
      position: absolute!important
      height: 100%!important
      width: 3px!important
      left: 0!important
      top: 0!important
      content: ""

.links-style
  a
    fs: 10px
    fw: 500
    color: $color_blue
    line-height: 16px
    transition: .3s
    &:hover
      color: darken($color_blue, 10%)
      transition: .3s