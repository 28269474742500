
$margin_zero: 0px
$margin_xl: 65px
$margin_lg: 60px
$margin_large: 40px
$margin_md: 30px
$margin_mid: 26px
$margin_sm: 20px
$margin_xs: 15px
$margin_xxs: 11px

$margin: () !default
$margin: append($margin, ('zero', $margin_zero))
$margin: append($margin, ('xl', $margin_xl))
$margin: append($margin, ('lg', $margin_lg))
$margin: append($margin, ('large', $margin_large))
$margin: append($margin, ('md', $margin_md))
$margin: append($margin, ('mid', $margin_mid))
$margin: append($margin, ('sm', $margin_sm))
$margin: append($margin, ('xs', $margin_xs))
$margin: append($margin, ('xxs', $margin_xxs))


.margin
  &--
    @each $size, $value in $margin
      &top_#{$size}
        +mq-both-adaptive
          margin-top: $value
        
      &bottom_#{$size}
        +mq-both-adaptive
          margin-bottom: $value

      &left_#{$size}
        +mq-both-adaptive
          margin-left: $value

      &right_#{$size}
        +mq-both-adaptive
          margin-right: $value

      &center
        margin: 0 auto