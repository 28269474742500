.line-wrapper
  position: relative
  z-index: 1

.title-line
  &:before
    position: absolute
    top: 15px
    content: ''
    w: 22px
    h: 4px
    left: -10px
    z-index: -1
    background-color: $bg-color-blue
    +mq-lg()
      w: 27px
      left: -30px
      top: 22px
      w: 46px