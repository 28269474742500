.nofixed,
.hidden
  top: 0

.hidden
  transform: translateY(-370px)

.fixed-menu
  w: 100%
  z-index: 999
  position: fixed
  transform: translateY(0)
  transition: transform 0.9s
  -webkit-box-shadow: 0px 4px 14px 1px rgba(0,0,0,0.10)
  -moz-box-shadow: 0px 4px 14px 1px rgba(0,0,0,0.10)
  box-shadow: 0px 4px 14px 1px rgba(0,0,0,0.10)
